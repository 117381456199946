import ChinaOnly from '@components/common/ChinaOnly'
import DiscoverMenu from '@components/common/MobileNav/MobileMenuToggler/Menu/Menus/DiscoverMenu'
import { Desktop } from '@components/common/Responsive'
import FestivalLink from '@components/layout/FestivalLink'
import ROUTING from '@constants/url'
import { isGreaterChina, isSmallChina } from '@utils/i18n'
import React, { useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import AccountMenu from './AccountMenu'
import BlogMenu from './BlogMenu'
import BusinessMenu from './BusinessMenu'
import './styles.css'
import SubMenu from './SubMenu'
import WalletMenu from './WalletMenu'
import PriceWithCurrency from '@components/common/PriceWithCurrency'
import ReferralLabel from './ReferralLabel'
import ProfileMenu from '@components/common/MobileNav/MobileMenuToggler/Menu/Menus/ProfileMenu'
import { useMyCountry } from '@hooks/useAuth'
import { useAddress } from '@hooks/useAddress'
import GetBaopalsProButton from '@components/common/GetBaopalsProButton'

const Menus = () => {
  const [open, setOpen] = useState<string>()
  const { locationCountryCode } = useMyCountry();
  const { defaultAddress } = useAddress();

  const handleToggle = (type: string) => setOpen(open == type ? '' : type)

  const getProps = (type: string) => ({
    open: open == type,
    onToggleLabel: () => handleToggle(type),
  })

  const renderFestival = <FestivalLink className="tw-py-4 px-4 bd-b-light d-block" />

  return (
    <Scrollbars
      styleName="scroller"
      style={{ maxHeight: 'calc(100% - 172px)' }}
      renderTrackHorizontal={(prop: any) => (
        <div {...prop} style={{ display: 'none' }} className="track-horizontal" />
      )}
    >
      {/*{isSmallChina(defaultAddress?.country || locationCountryCode) && (*/}
        {/*<div className="px-4 bd-b-light">*/}
          {/*<SubMenu renderLabel={<GetBaopalsProButton />} />*/}
        {/*</div>*/}
      {/*)}*/}
      <SubMenu renderLabel={renderFestival} />
      {/*<ChinaOnly isChina={isGreaterChina}>*/}
      {/*<SubMenu renderLabel={renderFestival} />*/}
      {/*</ChinaOnly>*/}
      <ChinaOnly>
        <SubMenu label="BAOPALS EXPRESS" labelLink="/express/category" />
      </ChinaOnly>
      <SubMenu label="DEPARTMENTS" labelLink="/departments" />

      <DiscoverMenu {...getProps('discover')} />

      <AccountMenu {...getProps('account')} />

      <ProfileMenu {...getProps('profile')} />

      <WalletMenu {...getProps('wallet')} />

      <BlogMenu {...getProps('blog')} />

      {/*<BusinessMenu {...getProps('business')} />*/}
      <SubMenu label="clothing size guide" labelLink={ROUTING.CLOTHING_SIZE_GUIDE} />
      <SubMenu label="help center" labelLink="/help" />
      <SubMenu renderLabel={<ReferralLabel />} />

      <ChinaOnly
        otherwiseRender={<SubMenu label="SHIPPING FEES" labelLink="/overseas-shipping-rates" />}
      />
    </Scrollbars>
  )
}

export default Menus
