enum PaymentChannel { }

export enum PaymentMethods {
  WechatPay = 'wechatpay',
  AliPay = 'alipay',
  UnionPay = 'unionpay',
  PayPal = 'paypal',
  BaopalsWallet = 'baopals_wallet',
  VisaCard = 'airwallex_visa_card',
  MasterCard = 'airwallex_master_card',
  Fps = 'fps',
  ApplePay = 'apple_pay',
  GooglePay = 'google_pay',
  Unknown = 'unknown',
  AliPayHk = 'alipayhk',
}

export enum PaymentMethodCode {
  UnionPayPC = 'upacp_pc',

  PayPal = 'paypal',

  AirwallexCard = 'airwallex_card',
  AirwallexFps = 'fps',
  AliPayHkPC = 'alipay_hk_qr',
  AliPayHkWap = 'alipay_hk_wap',
  AliPayHkApp = 'alipay_hk_app',

  ApplePay = 'apple_pay',
  GooglePay = 'google_pay',

  AliPayApp = 'alipay',
  AliPayWap = 'alipay_wap',
  AliPayPC = 'alipay_pc_direct',

  WechatPayPC = 'yeepay_wx_pub_qr',
  WechatPayApp = 'yeepay_wx_pub_ofl',
  WechatPayWap = 'yeepay_wx_lite_ofl',
  WechatOldPayPC = 'wx_pub_qr',
  WechatOldPayApp = 'wx_pub',
  WechatOldPayWap = 'wx_wap',

  Stripe = 'stripe',

  BaopalsWallet = 'baopals_wallet',
}

export enum PaymentStatus {
  PENDING = 'pending',
  PAID = 'paid',
  CANCELD = 'canceled',
}

export interface Payment {
  id: string
  channelId: PaymentMethodCode
  channel: string
  amount: number
  status: PaymentStatus
  description: string
  originalCharge: string
  provider: string
  providerBaseUrl?: any
  scheme?: any
}

export interface Channel {
  id: string
  feeMark: number
  feeRate: number
  supportsRefund: boolean
}

export interface BillingAddress {
  city: string
  countryCode: string
  postCode?: string
  state?: string
  street: string
}

export interface Billing {
  firstName: string
  lastName: string
  email?: string
  address: BillingAddress
}

export interface CreditCard {
  number: string
  cvc: string
  expirationDate: string
}
