import ProductUnavailable from '@components/product/v4/ProductDetails/ProductUnavailable'
import {Platform} from '@interfaces/platform'
import {truthyOrZero} from '@utils/objectUtils'
import {capitalized} from '@utils/string'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import './styles.css'
import { cloudObjectToURL } from '@utils/cloudStorage'
import { Link } from 'react-router-dom'
import { useProductImage } from '@hooks/useProductImages'

interface AvailableProps {
  min: number
  max: number
  withBrackets: boolean
  unable?: string
  platform: Platform
}

const Available: React.SFC<AvailableProps> = ({min, max, withBrackets, unable, platform, id }) => {
  const { selected: selectedImage } = useProductImage(id)
  const hideQTY = ['stock', 'area_supermarket'].includes(unable)

  if (!truthyOrZero(max)) {
    return null
  }

  if (hideQTY) {
    return (
      <div styleName="error">
        <ProductUnavailable unable={unable} platform={platform} id={id} />
      </div>
    )
  }

  if ((max === 0 || max < min) && unable !== 'area') {
    return (
      <div>
        <div className="text-danger">
          <FormattedMessage
            id="product_page.out_of_stock"
            values={{prefix: platform === Platform.JD ? 'Temporarily' : ''}}
          >
            {text => capitalized(text.join().trim())}
          </FormattedMessage>
        </div>{' '}
        {/*<Link to={`/search?img=${cloudObjectToURL(selectedImage)}`} className="blue-link bold">Click here</Link> to search for alternatives.*/}
      </div>
    )
  }

  return (
    <div className="fs12">
      {withBrackets && '('}
      {max}
      &nbsp;
      <FormattedMessage id="product_page.available" />
      {withBrackets && ')'}
    </div>
  )
}

export default Available
