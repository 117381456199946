import Signout from '@components/common/Signout'
import ROUTING from '@constants/url'
import useAuth, { useMyCountry } from '@hooks/useAuth'
import { openLoginModal } from '@hooks/useLoginModal'
import React from "react";
import { Nav } from 'react-bootstrap'
import GuestDropdown from './GuestDropdown'
import AnimatedDropdown from '@components/common/AnimatedDropdown'
import { Option } from './Option'
import GetBaopalsProButton from '@components/common/GetBaopalsProButton'
import './styles.css'
import { isSmallChina } from '@utils/i18n'
import { useAddress } from '@hooks/useAddress'

const Heading = ({ children, className }) => (
  <div className={`strong bd-b-dark tw-mb-2 tw-pb-2 ${className}`}>{children}</div>
);

const LoginWidget = () => {
  const { user } = useAuth();
  const { locationCountryCode } = useMyCountry();
  const { defaultAddress } = useAddress();

  const account_options = [
    {
      label: 'Orders',
      link: ROUTING.ACCOUNT_ORDERS,
    },
    {
      label: 'Warehouse',
      link: ROUTING.ACCOUNT_WAREHOUSE,
    },
    {
      label: 'Parcels',
      link: ROUTING.ACCOUNT_PARCELS,
    },
    {
      label: 'Addresses',
      link: ROUTING.ACCOUNT_ADDRESS,
    },
    {
      label: `Wallet`,
      link: ROUTING.ACCOUNT_MY_WALLET,
    },
    {
      label: `Settings`,
      link: ROUTING.ACCOUNT_SETTINGS,
    },
    {
      label: `Refer A Pal`,
      link: ROUTING.ACCOUNT_REFERRALS,
    },
  ]

  const profile_options = [
    {
      label: 'Reviews',
      link: `/discover/users/${user?.username}/reviews`,
    },
    {
      label: 'Shops',
      link: `/discover/users/${user?.username}/shops`,
    },
    {
      label: 'Wishlists',
      link: `/discover/users/${user?.username}/wishlists`,
    },
  ]

  return (
    <div>
      <Nav pullRight={true}>
        {user ? (
          <>
            <AnimatedDropdown
              className="fs14"
              title={`Hey ${user.displayName}!`}
              id="nav-dropdown-account"
              renderMenuItem={
                <div className="p-4" styleName="dropdownMenu">
                  <Heading className="flex-center-between">
                    My Account

                    <Signout>
                      {signout => (
                        <div onClick={signout} className="black-link">
                          <i className="fa-solid fa-right-from-bracket" />
                        </div>
                      )}
                    </Signout>
                  </Heading>
                  <div className="v4-row tw-pt-2">
                    {account_options.map((option) => (
                      <Option
                        label={option.label}
                        key={option.label}
                        link={option.link}
                        user={user}
                      />
                    ))}
                  </div>

                  <Heading className="tw-mt-6">My Favorites</Heading>
                  <div className="v4-row tw-pt-2">
                    {profile_options.map((option) => (
                      <Option
                        label={option.label}
                        key={option.label}
                        link={option.link}
                        user={user}
                      />
                    ))}
                  </div>

                  {/*{isSmallChina(defaultAddress?.country || locationCountryCode) && (*/}
                    {/*<div className="tw-my-2 d-flex justify-content-center">*/}
                      {/*<GetBaopalsProButton />*/}
                    {/*</div>*/}
                  {/*)}*/}
                </div>
              }
            />
          </>
        ) : (
          <GuestDropdown styleName='dropdown' onLogin={openLoginModal} />
        )}
      </Nav></div>
  );
};

export default LoginWidget;
